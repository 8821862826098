var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.formComponent, {
    tag: "component",
    scopedSlots: _vm._u(
      [
        {
          key: _vm.firstSlotName,
          fn: function () {
            return [
              _c(
                "sun-label-group",
                { attrs: { text: "New Password" } },
                [
                  _c("asterix-input", {
                    attrs: {
                      id: _vm.idNewPassword,
                      "auto-complete": _vm.autoComplete,
                      "auto-validate": _vm.autoValidate,
                      "class-input": _vm.classInput,
                      disabled: _vm.disabled,
                      name: "newPassword",
                      pattern: _vm.pattern,
                      "pattern-error-message": _vm.patternErrorMessage,
                      "min-strength": 3,
                      placeholder: "********",
                      readonly: _vm.readonly,
                      required: _vm.required,
                      type: "password",
                      "validate-on-blur": _vm.validateOnBlur,
                    },
                    on: { change: _vm.onChange, input: _vm.onInput },
                    model: {
                      value: _vm.newPassword,
                      callback: function ($$v) {
                        _vm.newPassword = $$v
                      },
                      expression: "newPassword",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: _vm.secondSlotName,
          fn: function () {
            return [
              _c(
                "sun-label-group",
                { attrs: { text: "Repeat New Password" } },
                [
                  _c("asterix-input", {
                    attrs: {
                      id: _vm.idRepeatNewPassword,
                      "auto-complete": _vm.autoComplete,
                      "auto-validate": _vm.autoValidate,
                      "class-input": _vm.classInput,
                      disabled: _vm.disabled,
                      error: _vm.errorRepeatNewPasswordMatch,
                      name: "repeatNewPassword",
                      "min-strength": 3,
                      pattern: _vm.pattern,
                      "pattern-error-message": _vm.patternErrorMessage,
                      placeholder: "********",
                      readonly: _vm.readonly,
                      required: _vm.required,
                      type: "password",
                      "validate-on-blur": _vm.validateOnBlur,
                    },
                    on: {
                      change: _vm.onChange,
                      error: _vm.onError,
                      input: _vm.onInput,
                    },
                    model: {
                      value: _vm.repeatNewPassword,
                      callback: function ($$v) {
                        _vm.repeatNewPassword = $$v
                      },
                      expression: "repeatNewPassword",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }