<template>
  <component :is="formComponent">
    <template #[firstSlotName]>
      <sun-label-group text="New Password">
        <asterix-input
          :id="idNewPassword"
          v-model="newPassword"
          :auto-complete="autoComplete"
          :auto-validate="autoValidate"
          :class-input="classInput"
          :disabled="disabled"
          name="newPassword"
          :pattern="pattern"
          :pattern-error-message="patternErrorMessage"
          :min-strength="3"
          placeholder="********"
          :readonly="readonly"
          :required="required"
          type="password"
          :validate-on-blur="validateOnBlur"
          @change="onChange"
          @input="onInput"
        />
      </sun-label-group>
    </template>
    <template #[secondSlotName]>
      <sun-label-group text="Repeat New Password">
        <asterix-input
          :id="idRepeatNewPassword"
          v-model="repeatNewPassword"
          :auto-complete="autoComplete"
          :auto-validate="autoValidate"
          :class-input="classInput"
          :disabled="disabled"
          :error="errorRepeatNewPasswordMatch"
          name="repeatNewPassword"
          :min-strength="3"
          :pattern="pattern"
          :pattern-error-message="patternErrorMessage"
          placeholder="********"
          :readonly="readonly"
          :required="required"
          type="password"
          :validate-on-blur="validateOnBlur"
          @change="onChange"
          @error="onError"
          @input="onInput"
        />
      </sun-label-group>
    </template>
  </component>
</template>

<script>
import { ERRORS } from '@/i18n/forms/errors';
import AsterixInput from './AsterixInput';
import FormRow from '@/components/atoms/FormRow/FormRow';
import FormColumn from '@/components/atoms/FormColumn/FormColumn';

export default {
  name: 'AsterixNewPasswordInput',
  components: {
    AsterixInput,
    FormRow,
    FormColumn,
  },
  props: {
    id: { type: String, default: () => undefined },
    disabled: {
      type: Boolean,
      default: () => undefined,
    },
    required: {
      type: Boolean,
      default: () => undefined,
    },
    readonly: {
      type: Boolean,
      default: () => undefined,
    },
    autofocus: {
      type: Boolean,
      default: () => undefined,
    },
    cancelError: {
      type: Boolean,
      default: () => undefined,
    },
    autoValidate: {
      type: Boolean,
      default: () => false,
    },
    autoComplete: {
      type: Boolean,
      default: () => undefined,
    },
    validateOnBlur: {
      type: Boolean,
      default: () => undefined,
    },
    classInput: {
      type: [String, Array, Object],
      default: () => '',
    },
    value: {
      type: [String, Number],
      default: () => undefined,
    },
    error: {
      type: [String, Boolean],
      default: () => false,
    },
    column: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    pattern: /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])(?=\S*[\W_])\S*$/,
    patternErrorMessage: ERRORS.en.passwordPattern,
    newPassword: '',
    repeatNewPassword: '',
    previewLastError: null,
  }),
  computed: {
    idNewPassword() {
      return `${this.id ? `${this.id}_` : ''}new_password`;
    },
    idRepeatNewPassword() {
      return `${this.id ? `${this.id}_` : ''}repeat_new_password`;
    },
    isPasswordMatch() {
      return this.newPassword === this.repeatNewPassword;
    },
    errorRepeatNewPasswordMatch() {
      return this.repeatNewPassword && !this.isPasswordMatch ? ERRORS.en.passwordMisMatch : this.previewLastError || '';
    },
    formComponent() {
      return this.column ? 'form-column' : 'form-row';
    },
    firstSlotName() {
      return this.column ? 'up' : 'left';
    },
    secondSlotName() {
      return this.column ? 'down' : 'right';
    },
  },
  methods: {
    onChange() {
      this.$emit('change', { newPassword: this.newPassword, repeatNewPassword: this.repeatNewPassword });
    },
    onInput(event) {
      if (this.isPasswordMatch) {
        this.$emit('input', event);
      }
    },
    onError({ type, text }) {
      this.previewLastError = type !== 'validation-from-parent' ? text : null;
    },
  },
};
</script>
