var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      class: ["flex flex-col w-full", _vm.data.class, _vm.data.staticClass],
      style: [_vm.data.style, _vm.data.staticStyle],
    },
    [
      _vm._t("default", function () {
        return [
          _c("div", [_vm._t("up")], 2),
          _c("div", { staticClass: "mt-6" }, [_vm._t("down")], 2),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }